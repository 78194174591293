@charset "UTF-8";

table {
    border-collapse: collapse;
}
#toggleBtn {
    text-decoration: underline;
    color: initial;
}
#toggleBtn:hover,
#toggleBtn:focus {
    color: var(--primary-color);
}

/* Comparison table */
#comparisonTable tr:nth-child(n+7) {
    display: none;
}
.comparison-table {
    font-size: 1rem;
    background-color: #fcfbff;
}
.comparison-table th {
    text-align: center;
    border-top: 0;
    border-bottom-color: #e6e1fd !important;
    font-weight: 600;
} 
.comparison-table thead img {
    width: 88px;
    height: 16px;
}
.comparison-table tbody td {
    text-align: center;
}
.comparison-table tbody tr td {
    border-top-color: #e6e1fd;
}
.comparison-table tbody tr:hover td {
    background-color: var(--bkg-light);
}
.comparison-table tbody td:first-child {
    text-align: left;
}
.comparison-table tbody img {
    width: 16px;
    height: 16px;
}
.comparison-table_info-icon {
    display: inline-block;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23c9bdff'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    top: 3px;
}
#comparisonTable.show tr:nth-child(n+7) {
    display: table-row;
}
