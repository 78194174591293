@charset "UTF-8";

.tooltip-inner {
    background-color: #fff;
    color: inherit;
    max-width: 300px;
    padding: .75rem;
    text-align: left;
}
.tooltip {
    box-shadow: 0 0 10px rgba(0,0,0,.2);
    border: 1px solid var(--primary-color);
}
.tooltip.show {
    opacity: 1;
}
.attach .arrow {
    border-color: var(--primary-color);
}