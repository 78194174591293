@charset "UTF-8";

/* dialog  */
.omnibook-loading-position {
    height: 0;
    padding-bottom: 60%;
    position: relative;
}
.omnibook-loading-position > .dots-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.omnibook-dialog > .modal-dialog > .modal-content > .modal-header {
    border-bottom: none;
}
.omnibook-dialog > .modal-dialog > .modal-content > .modal-body {
    padding: 0 3rem 4rem;
}
.omnibook-dialog-columns {
    display: grid;
    grid-template-columns: 1fr;
}
.omnibook-dialog-start {
    grid-row: 2;
    grid-column: 1;
}
.omnibook-dialog-end {
    grid-row: 1;
    grid-column: 1;
}
.omnibook-dialog-end {
    padding: 3rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.omnibook-dialog-actions {
    display: flex;
    flex-flow: row wrap;
    gap: .25rem;
}

.carousel-no-control > .carousel-indicators,
.carousel-no-control > .carousel-control-prev,
.carousel-no-control > .carousel-control-next {
    display: none;
}

.omnibook-dialog-title {
    line-height: 1;
}
.omnibook-dialog-description {
    line-height: 1.4;
}

@media (min-width: 992px) {
    .omnibook-dialog-columns {
        grid-template-columns: auto 40%;
        align-items: center;
    }
    .omnibook-dialog-start {
        grid-row: 1;
        grid-column: 1;
    }
    .omnibook-dialog-end {
        grid-row: 1;
        grid-column: 2;
    }
}