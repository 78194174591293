@charset "UTF-8";

:root {
    --body-color: #212529;
    --primary-color: #6600f6; 
    --primary-color-hover: #4f00be; 
    --secondary-color: #f6e100;   
    --bkg-light: hsl(265.16deg 93.94% 93.53% / 20%);
}

html {
    height: 100%;
    padding: 0;
    margin: 0;
}
body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--body-color);
}

.page {
    display: block;
    position: relative;
    width: 100%;
}
@media(max-width: 575.98px) {
    .page {
        padding: 0 10px;
    }   
}

.page-background {
    background-image: url("../img/home-bkg-1.jpg");
    background-position: center 50px;
    background-repeat: no-repeat;
    background-size: 80%;
}
@media(max-width: 575.98px) {
    .page-background {
        background-size: 200%;
    }   
}

.content {
    flex: 1;
    padding-top: 120px;
}
.content-solution {
    padding-top: 0;
}

/* Grid */
.grid {
    display: grid;
    position: relative;
}

@media(max-width:767.98px) {
    .grid-reverse {
        display: flex;
        flex-direction: column-reverse;
    }   
}

.grid-row-2 {
    grid-template-rows: repeat(2, 1fr);
}
@media(min-width:992px) {
    .grid-row-2 {
        grid-template-rows: none;
    }    
}

@media(min-width:768px) {
    .grid-col-2 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(min-width:768px) {
    .grid-col-3 {
        grid-template-columns: repeat(3, 1fr);
    }
}


@media(min-width:768px) {
    .grid-col-4 {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
}
@media(min-width:992px) {
    .grid-col-4 {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
    }
}

@media(min-width:992px) {
    .grid-col-5 {
        grid-template-columns: repeat(5, 1fr);
    }
}

.grid-col-6 {
    grid-template-columns: repeat(2, 1fr);
}
@media(min-width:576px) {
    .grid-col-6 {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media(min-width:768px) {
    .grid-col-6 {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media(min-width:992px) {
    .grid-col-6 {
        grid-template-columns: repeat(6, 1fr);
    }
}

/* grid reverse */
@media(max-width:991.98px) {
    .grid-col-reverse {
        display: flex;
        flex-direction: column-reverse;   
    }   
}

/* grid templates */
@media(min-width:768px) {
    .grid-col-2_1 {
        grid-template-columns: 2fr 1fr;
    }
}

@media(min-width:768px) {
    .grid-col-1_2 {
        grid-template-columns: 1fr 2fr;
    }
}

/* gap */
.gap-1 {
    gap: 1rem;
}
.gap-2 {
    gap: 2rem;
}
.gap-3 {
    gap: 3rem;
}
.gap-4 {
    gap: 4rem;
}
.gap-5 {
    gap: 5rem;
}

.gap-2-sm-0 {
    gap: 20px;
}
@media(min-width:768px) {
    .gap-2-sm-0 {
        gap: 2rem;
    }
}

.gap-4-sm-0 {
    gap: 20px;
}
@media(min-width:768px) {
    .gap-4-sm-0 {
        gap: 4rem;
    }
}

.gap-10-sm-0 {
    gap: 20px;
}
@media(min-width:768px) {
    .gap-10-sm-0 {
        gap: 10rem;
    }
}

/* width */
.w-100 {
    width: 100%;
    height: auto;
}

/* max-width */
.max-width-150 {
    width: 100%;
    height: auto;
    max-width: 150px;
    margin: 0 auto;
}
.max-width-300 {
    width: 100%;
    height: auto;
    max-width: 300px;
    margin: 0 auto;
}
.max-width-400 {
    width: 100%;
    height: auto;
    max-width: 400px;
    margin: 0 auto;
}  
.max-width-600 {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

@media(min-width:576px) {
    .max-width-900 {
        width: 100%;
        height: auto;
        max-width: 540px;
        margin: 0 auto;
    }   
}
@media(min-width:768px) {
    .max-width-900 {
        max-width: 720px;;
        margin: 0 auto;
    }   
}
@media(min-width:992px) {
    .max-width-900 {
        max-width: 900px;
    }   
}

@media(min-width:576px) {
    .max-width-1200 {
        width: 100%;
        height: auto;
        margin: 0 auto;
        max-width: 540px;
    }   
}
@media(min-width:768px) {
    .max-width-1200 {
        max-width: 720px;
    }   
}
@media(min-width:992px) {
    .max-width-1200 {
        max-width: 960px;
    }   
}
@media(min-width:1400px) {
    .max-width-1200 {
        max-width: 1200px;
    }   
}

/* justify */
.justify-center {
    justify-content: center;
}
.justify-items-c {
    justify-items: center;
}

/* padding */
.pad-0,
.pad-15,
.pad-30,
.pad-60,
.pad-90,
.pad-120 {
    position: relative;
    margin: 0 auto;
}
.pad-0 {
    padding: 0;
}
.pad-15 {
    padding: 16px; /* 4-Point Grid System */
}
.pad-30 {
    padding: 32px;
}

.pad-60 {
    padding: 60px 8px;
}
@media(min-width:992px) {
    .pad-60 {
        padding: 60px;
    }
}

.pad-90 {
    padding: 60px 8px;
}
@media(min-width:768px) {
    .pad-90 {
        padding: 92px;
    }
}

.pad-120 {
    padding: 92px 8px;
}
@media(min-width:768px) {
    .pad-120 {
        padding: 120px;
    }
}

/* padding left */
.pad-l-0 {
    padding-left: 0; 
}
.pad-l-15,
.pad-l-30,
.pad-l-60,
.pad-l-90 {
    padding-left: 8px;
}
@media(min-width:768px) {
    .pad-l-30,
    .pad-l-60,
    .pad-l-90 {
        padding-left: 20px;
    }
}
@media(min-width:992px) {
    .pad-l-15 {
        padding-left: 16px;
    }
    .pad-l-30 {
        padding-left: 32px;
    }
    .pad-l-60 {
        padding-left: 60px;
    }
    .pad-l-90 {
        padding-left: 92px; 
    }
}

/* padding right */
.pad-r-0 {
    padding-right: 0; 
}
.pad-r-15,
.pad-r-30,
.pad-r-60,
.pad-r-90 {
    padding-right: 8px;
}
@media(min-width:768px) {
    .pad-r-30,
    .pad-r-60,
    .pad-r-90 {
        padding-right: 20px;
    }
}
@media(min-width:992px) {
    .pad-r-15 {
        padding-right: 16px;
    }
    .pad-r-30 {
        padding-right: 32px;
    }
    .pad-r-60 {
        padding-right: 60px;
    }
    .pad-r-90 {
        padding-right: 92px; 
    }
}

/* padding bottom */
.pad-b-0 {
    padding-bottom: 0;
}
.pad-b-30 {
    padding-bottom: 32px;
}
.pad-b-60 {
    padding-bottom: 60px;
}
.pad-b-90 {
    padding-bottom: 92px;
}
.pad-b-120 {
    padding-bottom: 120px;
}

/* padding top */
.pad-t-60 {
    padding-top: 60px;
}
.pad-t-120 {
    padding-top: 120px;
}

/* margin */
.m-b-0 {
    margin-bottom: 0 !important;
}
.m-b-10 {
    margin-bottom: 8px !important;
}
.m-b-15 {
    margin-bottom: 16px !important;
}
.m-b-30 {
    margin-bottom: 32px !important;
}
.m-b-60 {
    margin-bottom: 60px !important;
}
.m-b-90 {
    margin-bottom: 92px !important;
}
.m-b-120 {
    margin-bottom: 120px !important;
}

.m-t-0 {
    margin-top: 0 !important;
}
.m-t-15 {
    margin-top: 16px !important;
}
.m-t-30 {
    margin-top: 32px !important;
}
.m-t-60 {
    margin-top: 60px !important;
}
.m-t-90 {
    margin-top: 92px !important;
}
.m-t-120 {
    margin-top: 120px !important;
}

.m-r-0 {
    margin-right: 0 !important;
}
.m-r-1 {
    margin-right: 1rem !important;
}

@media(min-width:992px) {
    .m-l-auto {
        margin: 0 0 0 auto;
    }
    .m-l-0 {
        margin-left: 0 !important;
    }
    .m-l-2 {
        margin-left: 2rem;
    }
    .m-r-auto {
        margin: 0 auto 0 0;
    }
    .m-r-2 {
        margin-right: 2rem;
    }
}

.m-l-0-sm {
    margin-left: 0 !important;
}   

/* align */
.align-c {
    align-items: center;
}

/* media */
img {
    max-width: 100%;
}
.media-cover {
    display: block;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.media-cover video,
.media-cover img {
    width: 100%;
    height: auto;
}
@media(min-width: 768px) {
    .media-cover video,
    .media-cover img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }  
}

.video-grid {
    overflow: hidden;   
}
.video-cover-170 video {
    position: absolute;
    width: 170%;
    height: 170%;
    object-fit: cover;
}

.position-top {
    object-position: top;
}
.position-left {
    object-position: left;
}
.position-right {
    object-position: right;
}
.position-top_right {
    object-position: top right;
}

/* background */
.bkg-white {
    background-color: #fff;
}
.bkg-light {
    background-color: var(--bkg-light);
    position: initial; /* FIREFOX issue if missing */
}
.bkg-main-color {
    background-color: var(--primary-color);
}
.bkg-gradient-1 {
    background: #6600f6;
    background: linear-gradient(45deg,#fd6579 10%,#6600f6 50%,#4a00b2 85%);
    animation: gradient 15s ease infinite;
    background-size: 300% 300%;
}
.bkg-gradient-1-light {
    background: var(--bkg-light);
    background: linear-gradient(45deg,hsl(352 97% 95% / 1) 10%,hsl(265 100% 95% / 1) 50%,hsl(265 100% 95% / 1) 85%);
    animation: gradient 15s ease infinite;
    background-size: 300% 300%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* border */
@media(min-width:992px) {
    .border-r-4 {
        border-right: 4px solid #fff;
    }   
}

/* SÉPARATEUR ENTRE BLOCS SUR MOBILE */
.header-separator {
    display: block;
    position: relative;
    width: 90%;
    height: 1px;
    background: rgb(22,169,226);
    background: linear-gradient(90deg, #FF5B6C 0%, #A438F6 50%, #6600F6 100%);
    margin: 48px auto;
} 
@media(min-width:768px) {
    .header-separator {
        display: none;
    }   
}

/* Shadow */
.shadow {
   box-shadow: 0 0 16px rgba(60,83,150,.75) !important; 
}
.shadow-hover {
    transition: box-shadow .15s ease-in-out;
}
.shadow-hover:hover,
.shadow-hover:focus {
    box-shadow: 0 0 16px rgba(60,83,150,.75);
}

/* Banner highlight */
.banner_highlight {
    display: block;
    background: #6600f6;
    background: linear-gradient(45deg,#fd5a6e 10%,#6600f6 50%,#4a00b2 85%);
    background-size: 300% 300%;
    animation: gradient 15s ease infinite;
}

.banner_highlight > div {
    display: grid;
    grid: auto auto / auto;
    margin-inline: auto;
    gap: 32px;
    padding: 4rem 1rem;
    color: #fff;
    place-items: center;
}
@media(min-width:768px) {
    .banner_highlight > div {
        grid: auto / 1fr auto;
        padding: 4rem 0;
        gap: 92px;
    }
}

.banner_highlight > div > div {
    display: contents;
}

/* Page about */
.grid-soutiens-img {
    display: inline-block;
    width: 180px;
    height: auto;
}
.grid-soutiens-img img {
    max-width: 180px;
    max-height: 80px;
    filter: grayscale(1);
}

/* Page PDF */
.pdf-args .material-symbols-outlined {
    font-size: 40px;
    color: var(--primary-color);
}

/* templates - exemples */
.grid-templates {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
}
@media(min-width:768px) {
    .grid-templates {
        grid-template-columns: repeat(3,1fr);
    }
}
@media(min-width:992px) {
    .grid-templates {
        grid-template-columns: repeat(4,1fr);
    }
}
@media(min-width:1400px) {
    .grid-templates {
        grid-template-columns: repeat(5,1fr);
    }
}

.grid-templates button {
    display: block;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    background: transparent;
    padding: 0;
    overflow: hidden;
    transition: transform .3s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.grid-templates button:hover {
    transform: scale(1.05);
}
.grid-templates button img {
    width: 100%;
    height: auto;
    aspect-ratio: 5 / 3;
}

/* landing page intro */
.landing-page-intro {
    gap: 1rem;
}
@media(min-width:768px) {
    .landing-page-intro {
        grid-template-columns: repeat(2, 1fr);
        gap: 4rem;
        padding-left: 1rem;
    }
}

@media(min-width:768px) {
    .landing-page-intro div:nth-child(2):not(.omnibook-list-item) {
        padding-right: 1rem;
    }
}

.landing-page-intro .omnibook-list-item {
    aspect-ratio: 5 / 3;
}

.landing-page-intro .omnibook-list-item-button {
    display: block;
    border: 1px solid #dad8d8;
    background: transparent;
    padding: 0;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: transform .3s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.landing-page-intro .omnibook-list-item-button:hover {
    transform: scale(1.05);
}

.landing-page-intro .omnibook-list-item-button > img {
    width: 100%;
    height: 100%;
}

.landing-page-intro .placeholder-glow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
}
@media(min-width:768px) {
    .landing-page-intro .placeholder-glow {
        gap: 1rem;
    }
}
@media(min-width:992px) {
    .landing-page-intro .placeholder-glow {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media(min-width:1600px) {
    .landing-page-intro .placeholder-glow {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* arrow intro */
.arrow-intro {
    display: none;
}
@media(min-width:992px) {
    .arrow-intro {
        display: grid;
        margin-bottom: 1rem;
    }
    .arrow-intro div:nth-child(2) {
        display: flex;
        flex-direction: row-reverse;
        padding-right: 0;
    }
    .arrow-intro div:nth-child(2) p {
        position: relative;
        text-align: center;
        margin-bottom: 0;
        font-family: "Caveat", cursive;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        font-size: 24px;
        line-height: 1.1;
    }
    .arrow-intro div:nth-child(2) p::before {
        content: "";
        display: block;
        position: absolute;
        top: -55px;
        left: 70%;
        width: 60px;
        height: 55px;
        background-image: url(../img/arrow.svg);
        background-repeat: no-repeat;
    }
}