@charset "UTF-8";

#mkLightboxContainer {
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1100;
}
#mkLightboxContainer img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}
#mkLightboxContainer > * {
    z-index: 99;
}
#mkLightboxContainer #overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    background: rgba(0,0,0,.85);
    z-index: 0;
}
#mkLightboxContainer #mklbInner {
    position: relative;
    height: 100vh;
    transition: all .4s ease-in-out;
    margin-left: 0;
}
#mkLightboxContainer section.imageContainer {
    margin: 0;
    padding: 1em;
    box-sizing: border-box;
    border: none;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
}
#mkLightboxContainer img,
#mkLightboxContainer video {
    background: #fff;
    box-shadow: 0 0 28px #222;
    max-height: 85vh;
    min-width: auto;
    max-width: 75vw;
    width: auto;
    height: auto;
}
#mkLightboxContainer #yt-video {
    width: 75vw;
    height: 42.1875vw; /* 75*9/16 */
}
#mkLightboxContainer .mklbItem:hover {
    cursor: pointer;
    font-style: italic;
}
#mkLightboxContainer #closeIconContainer {
    width: 25px;
    height: 25px;
    position: fixed;
    top: 16px;
    right: 16px;
    transition: transform .2s linear;
}
#mkLightboxContainer #closeIconContainer:hover {
    cursor: pointer;
    transform: rotate(90deg);
}
#mkLightboxContainer #closeIconContainer svg,
#mkLightboxContainer #prev svg,
#mkLightboxContainer #next svg,
#mkLightboxContainer #stopGallery svg, 
#mkLightboxContainer #playGallery svg{
    fill: white;
}
#mkLightboxContainer #prevContainer,
#mkLightboxContainer #nextContainer {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    padding: 8px 4px 12px 4px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in;
}
#mkLightboxContainer #nextContainer {
    left: auto;
    right: 0;
}
#mkLightboxContainer #prevContainer:hover,
#mkLightboxContainer #nextContainer:hover {
    cursor: pointer;
    background: rgba(0,0,0,0.25);
}
#mkLightboxContainer #prev,
#mkLightboxContainer #next {
    width: 35px;
    height: 35px;
}
#mkLightboxContainer .grid {
    display: grid;
    align-content: flex-start;
    grid-gap: 1em;
}
#mkLightboxContainer .grid-3 {
    grid-template-columns: repeat(3, 1fr);
}
#mkLightboxContainer .grid-4 {
    grid-template-columns: repeat(4, 1fr);
}
#mkLightboxContainer .grid figure {
    width: 100%;
    margin: 0;
}
#mkLightboxContainer .grid img {
    width: 100%;
}
#mkLightboxContainer #controlContainer {
    position: absolute;
    top: 1em;
    left: .5em;
    display: flex;
}
#mkLightboxContainer #controlContainer > div {
    width: 2em;
}
#mkLightboxContainer #controlContainer > div:hover {
    cursor: pointer;
}
#mkLightboxContainer #controlContainer #stopGallery,
#mkLightboxContainer #controlContainer.stop #playGallery{
    opacity: 0.5;
}
#mkLightboxContainer #controlContainer.stop #stopGallery,
#mkLightboxContainer #controlContainer #playGallery{
    opacity: 1
}
@media screen and (max-width: 768px){
    #mkLightboxContainer .grid-2-sm {
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 450px){
    #mkLightboxContainer .grid-12-xs {
        grid-template-columns: 1fr;
    }
}