@charset "UTF-8";

#testimonials {
    background-image: url("../img/home-bkg-3.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.testimonials-grid {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    flex-direction: column;
}
@media(min-width:992px) {
    .testimonials-grid {
        flex-direction: row;
    }
}

.testimonials-column {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
    flex-direction: column;
}


.testimonials-card {
    background-color: #fff;
    padding: 1.5rem;
    border: 1px solid #e6e1fd;
    border-radius: 6px;
}
.testimonials-item-text {
    margin-bottom: 30px;
}
.testimonials-item-author {
    display: grid;
    grid-template-columns: 80px 1fr;
    gap: 1rem;
    line-height: 1.2;
}
.testimonials-item-name {
    font-size: .75rem;
    font-weight: 600;
    margin-bottom: 0;
}
.testimonials-item-function {
    font-size: .75rem;
    margin-bottom: 0;
}

#testimonials ul {
    padding-left: inherit;
}