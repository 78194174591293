@charset "UTF-8";

/* Roboto {cyrillic, normal, 100} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, normal, 200} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, normal, 300} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, normal, 400} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, normal, 500} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, normal, 600} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, normal, 700} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, normal, 800} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, normal, 900} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic-ext, normal, 100} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, normal, 200} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, normal, 300} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, normal, 400} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, normal, 500} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, normal, 600} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, normal, 700} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, normal, 800} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, normal, 900} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {greek, normal, 100} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, normal, 200} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, normal, 300} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, normal, 400} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, normal, 500} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, normal, 600} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, normal, 700} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, normal, 800} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, normal, 900} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek-ext, normal, 100} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, normal, 200} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, normal, 300} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, normal, 400} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, normal, 500} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, normal, 600} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, normal, 700} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, normal, 800} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, normal, 900} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {latin, normal, 100} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, normal, 200} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, normal, 300} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, normal, 400} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, normal, 500} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, normal, 600} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, normal, 700} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, normal, 800} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, normal, 900} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin-ext, normal, 100} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, normal, 200} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, normal, 300} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, normal, 400} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, normal, 500} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, normal, 600} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, normal, 700} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, normal, 800} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, normal, 900} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {vietnamese, normal, 100} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, normal, 200} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, normal, 300} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, normal, 400} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, normal, 500} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, normal, 600} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, normal, 700} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, normal, 800} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, normal, 900} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {_extra_, normal, 100} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, normal, 200} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, normal, 300} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, normal, 400} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, normal, 500} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, normal, 600} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, normal, 700} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, normal, 800} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, normal, 900} */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Regular._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {cyrillic, italic, 100} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, italic, 200} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, italic, 300} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, italic, 400} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, italic, 500} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, italic, 600} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, italic, 700} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, italic, 800} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic, italic, 900} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic.woff2") format("woff2");
    unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

/* Roboto {cyrillic-ext, italic, 100} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, italic, 200} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, italic, 300} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, italic, 400} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, italic, 500} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, italic, 600} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, italic, 700} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, italic, 800} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {cyrillic-ext, italic, 900} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.cyrillic-ext.woff2") format("woff2");
    unicode-range: U+460-48F, U+492-4AF, U+4B2-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* Roboto {greek, italic, 100} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, italic, 200} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, italic, 300} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, italic, 400} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, italic, 500} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, italic, 600} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, italic, 700} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, italic, 800} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek, italic, 900} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek.woff2") format("woff2");
    unicode-range: U+370-3FF;
}

/* Roboto {greek-ext, italic, 100} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, italic, 200} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, italic, 300} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, italic, 400} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, italic, 500} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, italic, 600} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, italic, 700} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, italic, 800} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {greek-ext, italic, 900} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.greek-ext.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}

/* Roboto {latin, italic, 100} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, italic, 200} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, italic, 300} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, italic, 400} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, italic, 500} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, italic, 600} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, italic, 700} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, italic, 800} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin, italic, 900} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin.woff2") format("woff2");
    unicode-range: U+0, U+D, U+20-7E, U+A0-FF, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2002, U+2009, U+200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2022, U+2026, U+2032-2033, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto {latin-ext, italic, 100} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, italic, 200} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, italic, 300} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, italic, 400} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, italic, 500} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, italic, 600} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, italic, 700} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, italic, 800} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {latin-ext, italic, 900} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.latin-ext.woff2") format("woff2");
    unicode-range: U+100-130, U+132-151, U+154-24F, U+259, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* Roboto {vietnamese, italic, 100} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, italic, 200} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, italic, 300} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, italic, 400} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, italic, 500} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, italic, 600} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, italic, 700} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, italic, 800} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {vietnamese, italic, 900} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic.vietnamese.woff2") format("woff2");
    unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

/* Roboto {_extra_, italic, 100} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, italic, 200} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, italic, 300} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, italic, 400} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, italic, 500} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, italic, 600} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, italic, 700} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, italic, 800} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

/* Roboto {_extra_, italic, 900} */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("https://omnibook.com/fonts/google/roboto/Roboto-Italic._extra_.woff2") format("woff2");
    unicode-range: U+2, U+2C7, U+2C9, U+2D8-2D9, U+2DB, U+2DD, U+2F3, U+300-301, U+303, U+309, U+30F, U+323, U+2000-2001, U+2003-2008, U+200A, U+2010-2011, U+2015, U+2017, U+201B, U+2021, U+2025, U+2027, U+2030, U+203C, U+207F, U+2105, U+2126, U+212E, U+215B-215E, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+EE01-EE02, U+F6C3, U+FB01-FB04, U+FFFC;
}

