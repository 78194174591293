@charset "UTF-8";

lottie-player {
   width: 100%;
   height: auto;
   max-width: 500px;
   max-height: 500px;;
} 

lottie-player.lottie-intro {
   max-width: 900px;
   max-height: 900px;
}