@charset "UTF-8";

a[data-component="popover"] {
    text-decoration: underline !important;
}

.popover {
    border-radius: 0;
    border-color: var(--primary-color);
}
.popover-header {
    background-color: var(--bkg-light);
    border-bottom: none;
}