/* ScrollMagic */

.arguments-anim > * {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s ease-in-out;
}
.arguments-anim.visible > * {
    opacity: 1;
    transform: none;
}

