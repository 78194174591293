@charset "UTF-8";

.card-blog {
    display: grid;
    position: relative;
    border: 1px solid #e6e1fd;
    grid-template-rows: 100px auto;
    padding: 0;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}
.card-blog-image {
    display: block;
    position: relative;
    overflow: hidden;
}
.card-blog-image > img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
.card-blog-text {
    display: grid;
    grid-template-rows: auto 1fr auto;
    text-align: left;
    padding: 1rem;
}
.card-blog-text > p:first-child {
    font-size: .85rem;
    font-weight: 700;
    margin-bottom: 0;
}
.card-blog-text > p:last-child {
    margin-bottom: 0;
}
.card-blog-text > p:last-child::after {
    content: " →";
    display: inline-block;
    position: relative;
    margin-left: 5px;
    transition: transform 300ms;
}
.card-blog:hover .card-blog-text > p:last-child {
    color: var(--primary-color);
}
.card-blog:hover .card-blog-text > p:last-child::after {
    transform: translateX(5px);
}
.card-blog-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.blog-args .grid {
    grid-template-columns: 40px auto;
    gap: 1rem;
}
.blog-args .material-symbols-outlined {
    font-size: 40px;
    color: var(--primary-color);
}
.blog-args ul {
    padding-left: 16px;
}
.blog-logo img {
    width: 100px;
    margin: 0 auto;
}

/**/
.blog-args.grid-col-3 .grid-col-2 {
    grid-template-columns: 30px auto;
    gap: 5px;
}
.blog-args.grid-col-3 .material-symbols-outlined {
    font-size: 30px;
}