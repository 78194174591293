@charset "UTF-8";

/* Modal Comparison Table */
#comparisonTableModal .modal-header {
    display: none;
}
#comparisonTableModal .modal-footer {
    border-top: none;
}
#comparisonTableModal .modal-footer button {
    text-transform: none;
}
