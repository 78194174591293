@charset "UTF-8";

/* home arguments */
.contenu-home video {
    max-width: 100%;
}

/* accodion */
a[data-component="collapse"] {
    display: block;
    position: relative;
    padding: .5rem 25px .5rem;
    text-align: left;
}
a[data-component="collapse"]:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 16px;
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16.5 16.5' style='enable-background:new 0 0 16.5 16.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%236600f6;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cline class='st0' x1='8.2' y1='0.2' x2='8.2' y2='16.2'/%3E%3Cline class='st0' x1='0.2' y1='8.2' x2='16.2' y2='8.2'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    transition: transform .3s ease;
}
a[data-component="collapse"][aria-expanded="true"] {
    color: var(--primary-color);
}
a[data-component="collapse"][aria-expanded="true"]:before {
    transform: rotate(45deg);
}

a[data-component="collapse"]:hover {
    color: var(--primary-color);
}
a[data-component="collapse"][aria-expanded="false"]:hover:before {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16.5 16.5' style='enable-background:new 0 0 16.5 16.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%236600f6;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cg%3E%3Cline class='st0' x1='8.2' y1='0.2' x2='8.2' y2='16.2'/%3E%3Cline class='st0' x1='0.2' y1='8.2' x2='16.2' y2='8.2'/%3E%3C/g%3E%3C/svg%3E");
}

/* FAQ */
.page-faq .card-body,
.faq-model .card-body {
    background-color: var(--bkg-light);
    border-radius: 0;
    border: none;
    text-align: left;
    margin-bottom: 20px;
    margin-left: 0;
}
@media(min-width:576px) {
    .page-faq .card-body,
    .faq-model .card-body {
        padding-right: 32px;
    }    
}

.page-faq .card-body ul,
.faq-model .card-body ul {
    margin-bottom: 0;
}

.page-faq .card-body p:last-child,
.faq-model .card-body p:last-child {
    margin-bottom: 0;
}

.page-faq a[data-component="collapse"],
.faq-model a[data-component="collapse"] {
    padding: 4px 0 8px 24px;
    line-height: 1.4;
}
@media(min-width:576px) {
    .page-faq a[data-component="collapse"],
    .faq-model a[data-component="collapse"] {
        padding-right: 36px;
    }    
}

.page-faq a[data-component=collapse]:before,
.faq-model a[data-component=collapse]:before {
    display: inline-block;
    position: absolute;
    top: 12px;
    left: 0;
}

.faq-subtitle {
    font-weight: 600;
    margin-bottom: 0;
    padding-top: 2rem;
    text-transform: uppercase;
}
.faq-subtitle:first-child {
    padding-top: 0;
}

/* FAQ MORE (catalog) */
a.faq-more[data-component=collapse] {
    color: var(--primary-color);
}
a.faq-more[data-component=collapse]:before,
a.faq-more[data-component=collapse]:hover:before {
    content: none;
}

/* page security NEW */
#page-security a[data-component=collapse] {
    content: none;
    color: var(--primary-color);
    padding-left: 0;
}
#page-security a[data-component=collapse]:before,
#page-security a[data-component=collapse]:focus,
#page-security a[data-component=collapse]:hover:before,
#page-security a[data-component=collapse]:focus:before {
    content: none;
}
#page-security h5 {
    position: relative;
    padding-top: 1rem;
}
#page-security h5::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 1px;
    background-color: var(--primary-color);
}

/* SECURITY */
.security-subtitle {
    text-transform: uppercase;
    margin-bottom: 0;
    padding-top: 2rem;
    font-weight: 600;
}
.security-subtitle:first-child {
    padding-top: 0;
}

.page-security a[data-component="collapse"] {
    padding: 4px 0 4px 24px;
}

.page-security a[data-component="collapse"]:before {
    top: 12px;
}

.page-security .card-body {
    background-color: var(--bkg-light);
    border-radius: 0;
    border: none;
    text-align: left;
    margin-bottom: 20px;
    margin-left: 0;
}
@media(min-width:576px) {
    .page-security .card-body {
        padding-right: 32px;
    }    
}

.page-security .card-body p:last-child,
.page-security .card-body ul:last-child {
    margin-bottom: 0;
}

/* page tutos */
#page-tutorials h5 {
    position: relative;
    padding-top: 1rem;
}
#page-tutorials h5::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 1px;
    background-color: var(--primary-color);
}
#page-tutorials .toggleLink {
    font-size: 16px;
    padding: 0.5rem 0 0;
}
#page-tutorials .toggleLink::before {
    content: none;
}