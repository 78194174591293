@charset "UTF-8";

.form {
    display: block
}
.form-header:empty {
    display: none
}
.form-description {
    margin-bottom: 1rem
}
.form-description:empty {
    display: none
}
.field-group {
    margin-bottom: 15px
}
.control-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%
}
.control-group>.field-floating,
.control-group>.field-widget {
    flex-grow: 1
}
.control-group>.btn {
    height: auto
}
.control-group>.btn:not(:last-child),
.control-group>.field-floating:not(:last-child)>.field-widget,
.control-group>.field-widget:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}
.control-group>.btn:not(:first-child),
.control-group>.field-floating:not(:first-child)>.field-widget,
.control-group>.field-widget:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: calc(var(--border-width, 1)*-1)
}
.field-row {
    margin-bottom: 15px
}
.form-fields .field-row:last-child {
    margin-bottom: 30px;
}
.field-hidden {
    display: none !important
}
.form-errors:empty {
    display: none
}
.form-errors {
    margin-bottom: 1rem
}
.form-error-list {
    margin: 5px 0 0;
    padding: 0 0 0 18px
}
.field-errors:empty {
    display: none
}
.field-error {
    margin: 0
}
.field-label {
    cursor: pointer;
    display: inline-block;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 5px;
    max-width: 100%
}
.field-label:empty {
    display: none
}
.field-invalid>.field-label {
    color: #721c24
}
.field-required>.field-label:after {
    content: "*"
}
.field-description:empty,
.form-fields:empty,
.form-group-description:empty,
.hidden-field-row {
    display: none
}
.form-fields ::-moz-placeholder {
    color: #ccc
}
.form-fields ::placeholder {
    color: #ccc
}
.field-validation:empty {
    display: none
}
.validator-row {
    display: flex;
    flex-flow: row
}
.validator-description {
    flex: 1 1 auto;
    font-size: .8rem;
    line-height: 1.4rem;
    text-align: left
}
.validator-status {
    flex: 0 0 auto;
    text-align: right;
    width: 20px
}
.validator-ok>.validator-status {
    color: #44b06e
}
.validator-ok>.validator-status:after {
    content: "✓"
}
.validator-ko>.validator-status {
    color: red
}
.validator-ko>.validator-status:after {
    content: "✕"
}
.validator-none>.validator-status {
    color: #ccc
}
.validator-none>.validator-status:after {
    content: "…"
}
.field-actions>a:focus,
.form-actions>a:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25)
}
.form-actions:empty,
.form-links:empty {
    display: none
}
.form-actions>a,
.form-actions>button {
    margin-bottom: .25rem
}
.field-floating {
    position: relative
}
.field-floating>.field-widget {
    font-size: 1rem;
    height: calc(3.5rem + var(--border-width, 1)*2);
    line-height: 1.25;
    min-height: calc(3.5rem + var(--border-width, 2)*2);
    padding: 1rem .75rem
}
.field-floating>.field-widget::-moz-placeholder {
    color: transparent
}
.field-floating>.field-widget::placeholder {
    color: transparent
}
.field-floating>.field-widget:not(:-moz-placeholder) {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}
.field-floating>.field-widget:focus,
.field-floating>.field-widget:not(:placeholder-shown) {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}
.field-floating>.field-widget:-webkit-autofill {
    padding-bottom: .625rem;
    padding-top: 1.625rem
}
.field-floating>.field-label {
    border: var(--border-width, 1) solid transparent;
    height: 100%;
    left: 0;
    margin: 0;
    overflow: hidden;
    padding: 1rem .75rem;
    pointer-events: none;
    position: absolute;
    text-align: start;
    text-overflow: ellipsis;
    top: 0;
    transform-origin: 0 0;
    white-space: nowrap;
    z-index: 2
}
.field-floating.field-loaded>.field-label {
    transition: opacity .1s ease-in-out, transform .1s ease-in-out
}
.field-floating>.field-widget:not(:-moz-placeholder)~.field-label {
    color: rgba(var(--body-color-rgb), .65);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}
.field-floating>.field-widget:focus~.field-label,
.field-floating>.field-widget:not(:placeholder-shown)~.field-label {
    color: rgba(var(--body-color-rgb), .65);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}
.field-floating>.field-widget:-webkit-autofill~.field-label {
    color: rgba(var(--body-color-rgb), .65);
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}
.field-floating>.field-widget:not(:-moz-placeholder)~.field-label:after {
    background-color: transparent;
    border-radius: var(--border-radius);
    content: "";
    height: 1.5em;
    inset: 1rem .375rem;
    position: absolute;
    z-index: -1
}
.field-floating>.field-widget:focus~.field-label:after,
.field-floating>.field-widget:not(:placeholder-shown)~.field-label:after {
    background-color: transparent;
    border-radius: var(--border-radius);
    content: "";
    height: 1.5em;
    inset: 1rem .375rem;
    position: absolute;
    z-index: -1
}
input.field-widget,
select.field-widget,
textarea.field-widget {
    background-color: #ffffff;
    border: 1px solid #ecdffe;
    border-radius: 3px;
    color: #555;
    display: block;
    font-size: 14px;
    height: auto;
    line-height: 1.42857143;
    padding: 6px 12px;
    width: 100%
}
select.field-widget {
    padding: .375rem 2.25rem .375rem .75rem
}
select.field-widget:disabled {
    background-color: #e9ecef
}
input.field-widget[type=checkbox],
input.field-widget[type=radio] {
    background-color: initial;
    border: initial;
    box-shadow: none !important;
    display: inline;
    width: auto
}
input.field-widget[type=range] {
    padding: 6px 0
}
.field-widget:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    outline: none
}
.field-invalid .field-widget {
    border-color: #721c24
}
.digicode-widget {
    display: flex;
    flex-flow: row;
    justify-content: center
}
.digicode-widget>input {
    background: transparent;
    border: 1px solid;
    height: 40px;
    text-align: center;
    width: 40px
}
.digicode-uppercase>input {
    text-transform: uppercase
}
.form-checkbox-row {
    align-items: center;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    margin-bottom: 4px;
    margin-left: -6px;
    margin-right: -6px
}
.form-checkbox-row>.field-widget {
    margin: 0 15px 0 6px;
    order: 1
}
.form-checkbox-row>.field-label {
    margin: 0;
    order: 2
}
.field-disabled>.checkmark {
    opacity: .5;
    pointer-events: none
}
.checkmark.field-widget {
    border: 1px solid #ced4da
}
.file-widget {
    align-items: center;
    display: flex;
    justify-content: flex-start
}
.file-widget>.file-widget-message,
.file-widget>input {
    display: none
}
.file-widget-actions>button {
    margin-right: 10px
}
.image-field-row img {
    display: block;
    margin: 0 auto;
    max-height: 200px;
    max-width: 300px
}
.field-preview {
    background-color: #e5e7e9;
    border-radius: 5px;
    margin: 10px 0;
    padding: 20px 0;
    position: relative
}
.field-preview-img.field-preview-img-empty {
    background-color: #fff
}
.field-preview>.field-preview-placeholder {
    display: none;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px
}
.field-preview-img.field-preview-img-empty+.field-preview-placeholder {
    display: block
}
.form-action-button {
    border: 1px solid transparent;
    border-radius: .25rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    min-width: 4rem;
    padding: .375rem .75rem;
    text-align: center;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap
}
@media (min-width:576px) {
    .form-action-button {
        margin-bottom: 0
    }
}
.form-action-button:not(:first-child) {
    margin-left: .4rem
}
.form-action-button.form-action-button-secondary {
    background: #fff;
    color: #284449
}
.form-action-button.form-action-button-secondary:hover {
    text-decoration: underline
}
.form-action-button.form-action-button-secondary:focus {
    box-shadow: 0 0 0 1px #284449
}
.form-action-button[type=submit] {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff
}
.form-action-button[type=submit]:hover {
    background-color: #0069d9;
    border-color: #0062cc;
    color: #fff
}
.form-action-button[type=submit]:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}
.form-action-button[type=submit].active,
.form-action-button[type=submit]:active {
    background-color: #0062cc;
    border-color: #005cbf;
    color: #fff
}
.form .disabled,
.form [disabled] {
    opacity: .5;
    pointer-events: none
}
.form .choice-container {
    display: block
}
.field-choice-row {
    align-items: center;
    display: flex;
    flex-flow: row;
    margin: .5rem 0
}
.field-choice-row-label {
    margin: 0 5px
}
.btn-palette {
    display: block;
    float: left;
    height: 1.25rem;
    margin-bottom: 2px;
    margin-right: 2px;
    min-height: auto;
    padding: 0;
    width: 1.25rem;
    --btn-hover-color: $form-field-border-color
}
.btn-palette.selected,
.btn-palette:focus.selected {
    outline: 2px solid var(--primary)
}
.btn-palette-color {
    border: 1px solid #ced4da;
    border-radius: 3px;
    display: block;
    height: 0;
    padding-bottom: calc(100% - 2px);
    width: 100%
}
.btn-palette-empty {
    float: none
}
.btn-palette-empty>.btn-palette-color {
    border: none
}
.field-collection-row-choice,
.field-collection-row-text {
    align-items: flex-end;
    display: flex;
    flex-flow: row;
    justify-content: space-between
}
.field-collection-row-choice>.field-row,
.field-collection-row-text>.field-row {
    flex: 1 1 auto
}
.field-collection-row-choice>.field-collection-row-actions,
.field-collection-row-text>.field-collection-row-actions {
    flex: 0 0 auto;
    margin-bottom: 15px;
    margin-left: 10px
}
.field-collection-row-choice>.field-row>.field-widget,
.field-collection-row-text>.field-row>.field-widget {
    flex: 1 1 100%;
    width: auto
}