@charset "UTF-8";

/* tabs home - points forts */
.tabs-highlights .tab-content > .active {
    padding-top: 3rem;
}

@media(min-width:576px) {
    .tabs-highlights .nav {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

.tabs-highlights .nav.nav-pills {
    gap: 1rem;
}
.tabs-highlights .nav-fill .nav-item, 
.tabs-highlights .nav-fill>.nav-link {
    display: flex;
}
.tabs-highlights .nav-pills .nav-link {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    color: #fff;
    line-height: 1.3;
    padding: 1rem;
    background-color: var(--primary-color);
}
.tabs-highlights .nav-pills .nav-link.active, 
.tabs-highlights .nav-pills .show>.nav-link {
    background-color: #fff;
    color: var(--primary-color);
    box-shadow: 0 0 30px rgba(102,0,246,.7);
}

/* FAQ */
.page-faq .tab-content > .active {
    padding-top: 3rem;
}
.page-faq .nav.nav-pills {
    gap: .5rem;
}
.page-faq .nav-pills .nav-link {
    color: var(--primary-color);
    background-color: var(--bkg-light);
}
.page-faq .nav-pills .nav-link.active, 
.page-faq .nav-pills .show>.nav-link {
    background-color: var(--primary-color);
    color: #fff;
}