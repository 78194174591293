@charset "UTF-8";

@media(min-width:576px) {
    #home h2 {
        font-size: 48px;
    }
    #home h3 {
        font-size: 32px;
        line-height: 1;
    }
    #home h2 + p {
        font-size: 20px;
    }
}
.contenu {
    display: none;
}
.actif {
    color: var(--primary-color) !important;
}
a[data-cible] {
    display: block;
    position: relative;
    font-size: 32px;
    line-height: 1;
    text-decoration: none;
    padding-right: 30px;
    padding-bottom: 1rem;
}
a[data-cible]::after {
    content: "❯";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    rotate: 90deg;
    font-size: smaller;
}
a[data-cible].actif::after {
    rotate: -90deg;
}



