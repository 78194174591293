@charset "UTF-8";

.download-container {
    padding: 40px 15px;
    text-align: center;
}

.download-container .card-deck {
    display: flex;
    flex-direction: column;
}
@media (min-width: 576px) {
    .download-container .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }    
}

.download-container > .card-deck {
    justify-content: center;
}
@media (max-width: 767px) {
    .download-container>.card-deck {
        align-items: center;
    }   
}

.download-container .card {
    display: flex;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 3px;
}

.card-deck .card {
    margin-bottom: 16px;
}
@media (min-width: 576px) {
    .download-container .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 16px;
        margin-bottom: 0;
        margin-left: 16px;
    }   
}
@media (min-width: 576px) {
    .download-container .card-deck, .card-deck .card {
        -webkit-box-direction: normal;
    }   
}

.download-container > .card-deck > .card {
    min-width: 280px;
    max-width: 280px;
}
.download-container > .card-deck > .card.card-primary {
    margin: 0 auto;
}

.download-container .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.download-container .card-header:first-child {
    border-radius: 2px 2px 0 0;
}
.download-container > .card-deck > .card > .card-header > h4 {
    font-size: 1.4rem;
    font-weight: 600;
}
.download-container .bg-primary {
    background-color: var(--primary-color)!important;
}
.download-container .text-white {
    color: #fff!important;
}

.download-container .card-body {
    background-color: #fff;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.download-container .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 5px;
    text-transform: lowercase;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.download-container .btn-outline-primary {
    color: var(--primary-color);
    background-color: transparent;
    background-image: none;
    border-color: var(--primary-color);
    text-decoration: none;
    text-transform: capitalize;
}
.download-container .btn-outline-primary:hover {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}
.download-container .btn-outline-secondary {
    color: #68777c;
    background-color: transparent;
    background-image: none;
    border-color: #68777c;
    text-decoration: none;
    text-transform: capitalize;
}
.download-container .btn-outline-secondary:hover {
    color: #fff;
    background-color: #68777c;
}
.download-container .btn-block {
    display: block;
    width: 100%;
}

/* store buttons */
.download-container .btn-store {
    display: inline-block; /* FIREFOX issue if missing */
}
.download-container .btn-store, 
.download-container .btn-store:hover {
    border: none;
    background-color: transparent;
    color: transparent;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 170px;
}
.download-container .btn-store.pointerdown {
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 1);
}

/* androïd */
.btn-store-android {
    background-image: url("../img/android_en.png");
    height: 65.8px; /* 646 / 250 */
}
html[lang=fr] .btn-store-android {
    background-image: url("../img/android_fr.png");
}

/* iOS */
.btn-store-ios {
    background-image: url("../img/ios_en.png");
    height: 56.8px; /* 119.66407 / 40 */
}
html[lang=fr] .btn-store-ios {
    background-image: url("../img/ios_fr.png");
}

/* Windows */
.btn-store-win {
    background-image: url("../img/win_en.png");
    height: 61.4px;
}
html[lang=fr] .btn-store-win {
    background-image: url("../img/win_fr.png");
}

/* Mac*/
.btn-store-mac {
    background-image: url("../img/mac_en.svg");
    height: 43.56px; /* 156.10054 / 40 */
}
html[lang="fr"] .btn-store-mac {
    background-image: url("../img/mac_fr.svg");
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

.download-container .mb-3, 
.download-container .my-3 {
    margin-bottom: 1rem!important;
}
.download-container .mb-4, 
.download-container .my-4 {
    margin-bottom: 1.5rem!important;
}

.download-container .mt-0, 
.download-container .my-0 {
    margin-top: 0!important;
}