@charset "UTF-8";

.button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin: 4px 4px 4px 0;
    padding: .8125rem 1.25rem;
    font-weight: 500;
    color: #fff !important;
    text-decoration: none !important;
    text-align: center;
    vertical-align: middle;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.375rem;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, transform .3s;
}
.button:hover {
    color: #fff !important;
    background-color: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
    text-decoration: none;
    transform: translateY(-2px);
}

.button-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
}

.button-white {
    color: var(--primary-color) !important;
    border-color: #fff;
    background-color: #fff;
}
.button-white:hover {
    color: #fff !important;
    background-color: transparent;
    border-color: var(--white);
}

.button-outline {
    color: var(--primary-color) !important;
    border-color: var(--primary-color);
    background-color: transparent;
}
.button-outline:hover {
    color: #fff !important;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.button-outline-white {
    color: #fff !important;
    border-color: #fff;
    background-color: transparent;
}
.button-outline-white:hover {
    color: var(--primary-color) !important;
    background-color: #fff;
    border-color: #fff;
}

.button.button-color {
    color: #fff !important;
    background-color: var(--purple);
}
.button.button-color:focus,
.button.button-color:hover {
    background-color: #a73b8b;
    border-color: #a73b8b;
}

.btn-outline-secondary {
    color: #68777c !important;
}
.btn-outline-secondary:hover {
    color: #fff !important;
}

.btn-small {
    padding: 0.25rem 0.5rem;
}

a.btn-store {
    color: transparent !important;
}

.btn-link,
.btn-link:hover,
.btn-link:focus {
    text-transform: initial !important;  
    color: var(--primary-color);    
}

/* Home banner */
.button-primary {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--body-color) !important;
    border-radius: 2.5em;
    padding: 1em 2em;
}
.button-primary:hover,
.button-primary:focus {  
    color: var(--body-color) !important; 
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);   
}
.button-secondary {
    border-radius: 2.5em;
    padding: 1em 2em;
    border-color: var(--secondary-color);
    border-color: var(--body-color);
    color: var(--body-color) !important;
    background-color: rgb(255 255 255 / 30%);
}
.button-secondary:hover,
.button-secondary:focus {  
    color: var(--body-color) !important; 
    background-color: rgb(255 255 255 / 30%); 
    border-color: var(--body-color);  
}

/* pour SAFARI - sinon, mauvais design des boutons page téléchargement app */
[type=reset], 
[type=submit], 
button, 
html [type=button] {
    -webkit-appearance: none;
}