@charset "UTF-8";

::marker {
    color: var(--primary-color);
}
ul {
    padding-left: 14px;
    text-align: left;
} 
ol {
    padding-left: 20px;
    text-align: left;
}

.bullet li::marker {
    content:"⭑ "
}
.bullet li {
    line-height: 1.4;
    margin-bottom: 0.5rem;
}

/* tutorial container */
@media(min-width:992px) {
    .tutorial_container ol {
        padding-left: 0;
    }    
}

.tutorial_container li ::marker {
    font-weight: bold;
}
.tutorial_container li {
    padding-left: 16px;
}

/* List Links */
.list-links {
    padding-left: 0;
    margin-bottom: 0;
}
.list-links li {
    position: relative;
    line-height: 1.3;
}
.list-links li::marker {
    color: transparent;
}
.list-links li::after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    right: 0;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='currentColor' d='m6.47 4.29 3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
}
.list-links li:hover::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='#6600f6' d='m6.47 4.29 3.54 3.53c.1.1.1.26 0 .36L6.47 11.7a.75.75 0 1 0 1.06 1.06l3.54-3.53c.68-.69.68-1.8 0-2.48L7.53 3.23a.75.75 0 0 0-1.06 1.06z'%3E%3C/path%3E%3C/svg%3E");
}
.list-links a {
    display: block;
    position: relative;
    text-decoration: none;
    padding-right: 16px;
}
.list-links a:hover,
.list-links a:focus {
    color: var(--primary-color);
    text-decoration: underline;
    background-color: var(--bkg-light);
}
