@charset "UTF-8";

footer {
    display: block;
    position: relative;
    font-size: .85rem;
    text-align: center;
    flex: 0 0 auto;
    background-color: var(--bkg-light);
    padding-bottom: 50px;
}
@media(min-width: 992px) {
    footer {
        text-align: left;
        margin-top: auto!important;
        padding-bottom: 80px;
    }    
}

.footer-map {
    display: block;
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem 0 4rem;
}

footer .sitemap {
    display: block;
    position: relative;
    margin-bottom: 60px;
}
@media(min-width: 992px) {
    footer .sitemap {
        margin-bottom: 0;
    }
}

.sitemap-links {
    margin-bottom: 3rem;
}
@media(min-width: 576px) {
    .sitemap-links {
        display: grid;
        grid-template-columns: repeat(3,minmax(0, 1fr));
        text-align: left;
        column-gap: 12px;
        row-gap: 40px;
    }   
}
@media(min-width: 768px) {
    .sitemap-links {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        text-align: left;
    }   
}

.footer-grid {
    display: grid;
    gap: 10px;
}
@media(min-width: 576px) {
    .footer-grid {
        gap: 8px;
        grid-template-columns: repeat(3,1fr);
    }    
}

@media(min-width: 576px) {
    .sitemap-links div:nth-child(1) .footer-grid {
         grid-template-columns: repeat(2,1fr);
     } 
 }
 @media(min-width: 576px) {
     .sitemap-links div:nth-child(3) .footer-grid {
          grid-template-columns: repeat(2,1fr);
      } 
  }

@media(min-width: 992px) {
    .sitemap-links > div:nth-child(2) .footer-grid {
        gap: 20px;
    }
}

.footer-title p {
    margin-bottom: 3px;
}
@media(min-width: 576px) {
   .footer-title p {
        margin-bottom: 1rem;
    } 
}

.footer-title > a {
    line-height: inherit !important;
}

.footer-title:after {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    height: 1px;
    background-color: var(--primary-color);
    margin: 16px 0;
    opacity: .2;
}

.sitemap-links a {
    display: block;
    line-height: 1.2;
    margin-bottom: 12px;
    text-decoration: none;
}
.sitemap-links a:hover {
    text-decoration: underline !important;
    color: var(--primary-color) !important;
}
.sitemap-links a:focus {
    color: var(--body-color) !important;
}
.sitemap-legal {
    display: grid;
    position: relative;
    text-align: left;
    padding: 0 1rem;
    gap: 20px;
    font-size: 12px;
}
@media(min-width: 576px) {
    .sitemap-legal {
        display: flex;
        position: relative;
        justify-content: space-between;
    }    
}
@media(min-width: 1200px) {
    .sitemap-legal {
        padding: 0;
    }    
}

.sitemap-legal:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--primary-color);
    margin-top: -10px;
    opacity: .2;
    left: 0;
}

.sitemap-legal > div {
    display: grid;
}
@media(min-width: 576px) {
    .sitemap-legal > div {
        grid-auto-flow: column;
    }    
}

.sitemap-legal > div > * {
    margin-bottom: 0;
    margin-right: 2rem;
}
.sitemap-legal a {
    text-decoration: none;
    color: var(--body-color);
}
.sitemap-legal a:hover {
    text-decoration: underline;
    color: var(--body-color);
}

.sitemap-linkedin,
.sitemap-facebook,
.sitemap-youtube {
    display: inline-block !important;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    margin-right: 4px;
    transition: transform .3s ease;
}
.sitemap-linkedin {
    background-image: url(../img/social-linkedin.svg);
}
.sitemap-facebook {
    background-image: url(../img/social-facebook.svg);
}
.sitemap-youtube {
    background-image: url(../img/social-youtube.svg);
}

.sitemap-linkedin:hover,
.sitemap-facebook:hover,
.sitemap-youtube:hover {
    transform: translateY(-2px);
}

 /* ARROW UP */
.arrow-up {
    display: block;
    position: relative;
    width: 30px;
    height: 15px;
    margin: 0 auto;
}
.arrow-up > a {
    display: inline-block;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 78.4 40.2' style='enable-background:new 0 0 78.4 40.2;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%239231e8;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='77.4,39.2 39.2,1 1,39.2 '/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 15px;
    cursor: pointer;
    opacity: .75;
    transition: opacity .3s ease;
    animation: 1s cubic-bezier(1, 0.02, 1, 1) 0s infinite alternate arrow-anim;
}
.arrow-up > a:hover {
    opacity: 1;
}

@keyframes arrow-anim { 
    from { transform: translateY(-5px); } 
    to { transform: translateY(0); }  
}