@charset "UTF-8";

/* 
    JOIN - à propos 
*/
.join-box > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .2;
}
.join-box > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* features cards */
.features-card-grid {
    display: grid;
    position: relative;
    gap: 2rem;
    margin: 0 auto;
    margin-bottom: 2rem;
}
.features-card {
    display: grid;
    position: relative;
    grid-template-rows: auto 1fr;
}
.features-card:hover {
    color: var(--primary-color);
}

.features-card-img > img {
    width: 90px;
    height: 90px;
}

.features-card-content {
    display: block;
    position: relative;
    padding: 1rem;
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
}
.features-card-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;   
}

/* features list */
.features-list-grid {
    display: grid;
    position: relative;
    gap: 2rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    padding: 0 8px;
    width: 100%;
}
@media(min-width:992px) {
    .features-list-grid {
        gap: 3rem;
        padding: 0;
    }    
}

.features-list-grid > * {
    min-width: 0;
}

.features-list {
    position: relative;
    text-decoration: underline;
}
.features-list:hover {
    color: var(--primary-color);
}

.features-list-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;   
}

/* list users */
.list_users {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 50px;
    align-items: center;
    padding: 0 8px;
}
@media(min-width:576px) {
    .list_users {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2rem;
        align-items: center;
        margin: .5rem 0;
        padding: 0;
    }    
}

.list_users > img {
    width: 100px;
    height: 100px;
}

/* module solution */
.module_solutions {
    text-align: center;
    padding: 0 8px
}
@media(min-width:576px) {
    .module_solutions {
        padding: 0;
    }    
}

@media(min-width:576px) {
    .module_solutions_title {
        min-height: 50px;
        margin-bottom: 2rem;
    }    
}

.module_solutions_title h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
}
.module_solutions_image > img {
    height: 100px;
    width: 100px;
}

@media(min-width:576px) {
    .module_solutions_text {
        padding: 2rem 0;
    }   
}

/* testimonials */
.testimonials_container {
    position: relative;
    padding-left: 6rem;    
}
.testimonials_container:before {
    content: open-quote;
    position: absolute;
    top: -70px;
    left: -10px;
    font-size: 8rem;
    line-height: 1;
    font-family: monospace;
}
.testimonials_name {
    position: relative;
    margin-top: 32px;
    font-weight: 600;
}
.testimonials_name:before {
    content: "";
    display: inline-block;
    height: 1px;
    width: 39px;
    background-color: var(--primary-color);
    vertical-align: middle;
    margin-right: 20px;
}

/* Use cases - Examples */
.use_cases {
    display: grid;
    position: relative;
    text-align: center;
    justify-items: center;
    margin-bottom: 3rem;
}
.use_cases_caption p {
    text-decoration: underline;
    margin-bottom: 0;
    line-height: 1;
}
.use_cases:hover .use_cases_caption p {
    color: var(--primary-color);
    cursor: pointer;
}
.use_cases.bkg-dark:hover .use_cases_caption p {
    color: #fff;
    font-weight: 500;
    cursor: pointer;
}

.use_cases img {
    width: 170px;
    height: 170px;
    box-shadow: 0 0 20px rgb(0 0 0 / 40%);
    padding: .5rem;
    border-radius: 5px;
    will-change: padding;
    transition: padding 450ms;
}

.use_cases:hover img {
    padding: 0;
    transition: padding 125ms;
}

.use_cases_caption {
    margin-top: 16px;
}

.use_cases a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* module => homepage */
.module_key_uses {
    margin: 0 auto;
}
.module_key_uses img {
    width: 70px;
    height: auto;
    margin-bottom: 1rem;
}
.module_key_uses a {
    transition: none;
}
.module_key_uses a:hover {
    color: var(--primary-color);
}
