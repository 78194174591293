/** header.css */

.header {
    padding: .5rem 2rem .5rem 1rem;
    min-height: 4.5rem;
    display: flex;
    align-items: center;
}
.logo {
    width: 136px;
}
.menu-items {
    display: flex;
    flex-flow: fow;
    padding: 0 1rem;
    margin: 0;
    list-style-type: none;
}
.menu-item {
    padding: 0 1rem;
    position: relative;
}
.menu-link {
    text-decoration: none !important;
    font-weight: 500;
    background: none;
    border: none;
    padding: 0;
}
.menu-dropdown-link {
    display: flex;
    flex-flow: row;
    align-items: center;
}
.menu-dropdown-link::after {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    background: transparent url("../img/caret.svg") no-repeat 50%;
    margin-left: .5rem;
}
.menu-dropdown-link[aria-expanded="true"] {
    opacity: .6;
}
a.list-menu-link {
    display: block;
    padding: .5rem 1rem;
    text-decoration: none;
    white-space: nowrap;
    background: transparent;
}
@media (hover: hover) {
    .list-menu-link:not(:hover) {
        transition: background-color .1s ease-in-out;
    }
    .list-menu-link:hover {
        background-color: #eee;
    }
}

/* dropdown transition */
.dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    margin-top: 0;
    border-color: transparent;
    transition: all 0.15s ease;
}
.dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    margin-top: 7px;
    box-shadow: inset 0 1px 1px 0 hsl(0deg 0% 100% / 15%), 0 50px 100px -20px rgb(50 50 93 / 30%), 0 30px 60px -30px rgb(0 0 0 / 50%), -10px 10px 60px -10px rgb(131 103 178 / 30%)
}

.btn-signin {
    margin-left: auto;
}
