@charset "UTF-8";

/* page contact */
#page-contact {
    padding-bottom: 120px;
}
#page-contact.page-background {
    background-position: center;
    background-size: 80%;
}
#page-contact .omnibook-contact {
    margin-bottom: 16px;
}
.omnibook-contact .field-floating > textarea.field-widget {
    height: 100px;
}
#page-contact .form-actions button {
    border-radius: 2.5em;
    padding: 1em 2em;
}
#page-contact .btn-primary,
#page-contact .btn-primary.focus, 
#page-contact .btn-primary:focus, 
#page-contact .btn-primary:hover {
    color: var(--body-color);
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    transition: transform .3s;
}
#page-contact .btn-primary.focus, 
#page-contact .btn-primary:focus, 
#page-contact .btn-primary:hover {
    transform: translateY(-2px);
}
#page-contact img {
    filter: drop-shadow(0 0 16px rgba(0, 0, 0, .1));
}
