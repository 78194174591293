@charset "UTF-8";

#page-case-studies {
    background-image: url("../img/home-bkg-1.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
}
@media(min-width:992px) {
    #page-case-studies {
        background-size: contain;
        background-position: 100% 50px;
    }   
}

#page-case-studies h2 + p span {
    background-color: var(--primary-color);
    color: #fff;
    padding: 4px 16px;
    border-radius: 24px;
}
.case-studies-example {
    display: block;
    position: relative;
    background-color: #faf7fc;
    padding: 2rem;
    border-radius: 24px;
}
#page-case-studies video {
    width: 100%;
    max-width: 100%;
    height: auto;
}
#page-case-studies h5 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
    font-weight: 400;
    color: #6600f6;
}
.case-studies-arguments .grid>div,
.case-studies-result {
    border: 2px solid #fff;
    background-color: #ffffff66;
    padding: 2rem;
    border-radius: 24px;
}
.case-studies-result {
    box-shadow: 0 0 30px rgba(0,0,0,.1);
}
.case-studies-arguments ul {
    margin-bottom: 0;
}

/* page cas clients - rassemblement des cas clients */
.case-studies-container {
    display: flex;
}
.case_studies-grid {
    display: flex;
    gap: 2rem;
    flex-flow: wrap;
    padding-left: 0;
}
.case-studies-container .carousel-slide {
    flex: 1 0 30%;
    min-width: 250px;
}
