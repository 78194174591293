@charset "UTF-8";

.banner h1 {
    font-size: 60px;
    line-height: 1.1;
}
.banner-video video {
    width: 100%;
    height: auto;
    min-width: 100%;
    position: relative;
    box-shadow: 0 0 30px rgba(0,0,0,.2);
    border-radius: 12px;
}