@charset "UTF-8";

.icons {
    white-space: nowrap;
}
.icons:after {
    content: "";
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
    top: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    line-height: 1;
    background-color: #fff;
    background-size: 80%;
    margin: 0 4px;
    box-shadow: 4px 4px 0px #e3e3e3;
}

.icon-audio:after {
    background-image: url("../img/icons/audio.svg");
}
.icon-carousel:after {
    background-image: url("../img/icons/carousel.svg");
}
.icon-components:after {
    background-image: url("../img/icons/components.svg");
}
.icon-editor:after {
    background-image: url("../img/icons/editor.svg");
}
.icon-embed:after {
    background-image: url("../img/icons/embed.svg");
}
.icon-graph:after {
    background-image: url("../img/icons/graph.svg");
}
.icon-iframe:after {
    background-image: url("../img/icons/iframe.svg");
}
.icon-links:after {
    background-image: url("../img/icons/links.svg");
}
.icon-list:after {
    background-image: url("../img/icons/list.svg");
}
.icon-list-ordered:after {
    background-image: url("../img/icons/list_ordered.svg");
}
.icon-list-unordered:after {
    background-image: url("../img/icons/list_unordered.svg");
}
.icon-menu:after {
    background-image: url("../img/icons/menu.svg");
}
.icon-modal:after {
    background-image: url("../img/icons/modal.svg");
}
.icon-popover:after {
    background-image: url("../img/icons/popover.svg");
}
.icon-preview:after {
    background-image: url("../img/icons/preview.svg");
}
.icon-save:after {
    background-image: url("../img/icons/save.svg");
}
.icon-table:after {
    background-image: url("../img/icons/table.svg");
}
.icon-tabs:after {
    background-image: url("../img/icons/tabs.svg");
}
.icon-tooltip:after {
    background-image: url("../img/icons/tooltip.svg");
}
.icon-video:after {
    background-image: url("../img/icons/video.svg");
}