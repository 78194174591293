@charset "UTF-8";

.scroller {
    height: 132px;
    max-width: 1200px;
    overflow: hidden;
}
.scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}  
.scroller[data-animated="true"] .scroller__inner {
    mix-blend-mode: multiply;
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}
  
.scroller[data-direction="right"] {
    --_animation-direction: reverse;
}
.scroller[data-direction="left"] {
    --_animation-direction: forwards;
}
.scroller[data-speed="fast"] {
    --_animation-duration: 20s;
}
.scroller[data-speed="slow"] {
    --_animation-duration: 60s;
}

@keyframes scroll {
to {
    transform: translate(calc(-50% - 0.5rem));
}
}

.scroller__inner img {
    width: 100px;
    margin: 0 20px;
    aspect-ratio: 1 / 1;
    filter: grayscale(1);
    mix-blend-mode: multiply;
}

/* slick */
.slick-slider {
    display: inline-block !important;
    max-width: 80%;
}
@media(min-width:576px) {
    .slick-slider {
        display: block !important;
        max-width: none;
    }
}

.slick-track {
    display: flex !important;
    gap: 10px;
}
.slick-initialized .slick-slide {
    display: flex !important;
}
.slick-slide .card-description {
    flex: 1;    
}

.slick-slide .card-buttons {
    text-align: center;
}

.slick-dots li button:before {
    color: #fff !important;
}

.slick-next, 
.slick-prev {
    width: 30px !important;
    height: 30px !important;
    background-color: var(--primary-color) !important;
    border-radius: 50%;
    box-shadow: -5px 0 0 0 rgb(107 0 167 / 10%);
    border: 1px solid #fff;
    z-index: 1;
}
.slick-next {
    right: 10px !important;
}
.slick-prev {
    left: 10px !important;
    box-shadow: 5px 0 0 0 rgb(107 0 167 / 10%);
}
.slick-next:before, 
.slick-prev:before {
    content: "" !important;
    display: block;
    height: 100%;
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 30%;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 47.2 85.4' style='enable-background:new 0 0 47.2 85.4;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%23ffffff;stroke-width:9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='4.5,80.9 42.7,42.7 4.5,4.5 '/%3E%3C/svg%3E");
}
.slick-prev:before {
    transform: rotate(180deg);
}
.slick-prev.slick-disabled, 
.slick-next.slick-disabled,
.slick-prev.slick-disabled:before, 
.slick-next.slick-disabled:before {
    opacity: 0 !important;
}

/* carrousel other formats - slick.js */
#slider-carousel-other-format .slick-slide {
    position: relative;
    flex-direction: column;
    align-items: center;
    margin: 0 30px;
    align-items: flex-start;
    text-align: left;
}
#slider-carousel-other-format .slick-slide img {
    width: 70%;
    min-width: 200px;
    margin: 0 0 1rem;
    aspect-ratio: 5 / 3;
}
#slider-carousel-other-format .slick-slide h6 {
    font-weight: 600;
}
#slider-carousel-other-format .slick-slide p {
    flex: 1;
    font-size: 16px;
}
#slider-carousel-other-format .slick-prev {
    left: -25px !important;
}
#slider-carousel-other-format .slick-next {
    right: -25px !important;
}

