@charset "UTF-8";

@media(max-width:767.98px) {
    .navbar {
        box-shadow: 0 5px 20px 0 rgba(0,0,0,.1);
    }
    .navbar-collapse {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 20px;
    }
    .navbar-nav .dropdown-menu {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;
        row-gap: 0.25rem;
        opacity: 1;
        visibility: visible;
        padding: 0;
        border: 0;
    }
    .nav-item.dropdown:nth-child(2) {
        margin: 1rem 0;
    }
    .dropdown-toggle {
        font-weight: 500;
        text-decoration: none !important;
        padding: 0;
    }
    .dropdown-toggle::after {
        content: none;
    }
    .navbar .link-menu {
        order: 2;
    }
    .navbar .link-connexion {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: calc(100% - .5rem);
        gap: 1rem;
        order: 1;
        border-bottom: 1px solid rgba(0,0,0,.15);
        padding-bottom: .5rem;
        align-items: center;
        font-size: 1rem;
    }
}

@media(min-width:768px) {
    .navbar {
        box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
    }
    .navbar .link-connexion {
        gap: .75rem;
        align-items: center;
        font-size: 1rem;
    }
    .navbar .link-connexion li:last-child a:hover,
    .navbar .link-connexion li:last-child a:focus {
        color: var(--primary-color);
    }
}

@media(min-width:768px) {
    .navbar.navbar-expand-md .navbar-collapse {
        justify-content: space-between;
    }
}

.navbar .button {
    padding: 0.25rem 1.5rem;
    border-radius: 2.5em;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
    color: var(--body-color) !important;
}
@media(max-width:767.98px) {
    .navbar .button {
        width: 100%;
    }
}

.navbar .dropdown-item {
    text-decoration: underline;
    padding: 0;
}
@media(min-width:768px) {
    .navbar .dropdown-item {
        text-decoration: none;
        padding: initial;
        padding: 0.25rem 1.5rem;
    }
}

.navbar .dropdown-item:focus, 
.navbar .dropdown-item:hover {
    color: var(--primary-color);
    text-decoration: underline;
    background-color: transparent;
}

.dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Calque_2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10.7 6.3' style='enable-background:new 0 0 10.7 6.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:none;stroke:%236600f6;stroke-width:1.5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='0.8,0.8 5.3,5.5 9.9,0.8 '/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 11px;
    height: 6px;
    border: none;
    vertical-align: baseline;
    transition: transform 300ms ease;
}

@media(min-width:768px) {
    .navbar-collapse .navbar-nav:first-child {
        margin-left: auto;
    }
    .navbar-collapse .navbar-nav:nth-child(2) {
        margin: 0 auto;
    }
}

.navbar-light .navbar-nav .nav-link {
    color: var(--black);
    text-decoration: underline;
}
@media(min-width:768px) {
    .navbar-light .navbar-nav .nav-link {
        text-decoration: none;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
}

.navbar-light .navbar-nav .nav-link:hover {
    color: var(--primary-color);
}
.navbar-light .navbar-nav .nav-link[aria-expanded="true"] {
    color: var(--primary-color);
}
.navbar-light .navbar-nav .nav-link[aria-expanded="true"].dropdown-toggle::after {
    transform: rotate(180deg);
}
.navbar-brand {
    display: inline-flex;
}

.navbar-brand > img {
    width: 108px;
    height: 20px;
}
@media(min-width:576px) {
    .navbar-brand > img {
        width: 138px;
        height: 25px;
    }
}

@media(max-width:575.98px) {
    .navbar-toggler {
        font-size: .9rem;
        padding: 0.25rem 0.5rem;
    }
}

/* choix de langue */
.lang-selector {
    flex-direction: row;
    font-size: 14px;
    align-items: center;
}
@media(min-width:768px) {
    .lang-selector {
        margin-left: 1rem;
    } 
}

.lang-selector li {
    margin-left: 4px;
}
.lang-selector li a {
    display: inline-block;
    padding: .575rem .75rem;
    text-decoration: none;
}
@media(min-width:768px) {
    .lang-selector li a {
        display: inline-block;
        padding: 0;
    }
}

.lang-selector li a:hover,
.lang-selector li a:focus {
    color: var(--primary-color);
}