@charset "UTF-8";

@media(max-width: 576px) {
    br {
        display: none;
    }    
}

@media(min-width:576px) {
    h2 {
        font-size: 48px;
    }
    h3 {
        font-size: 32px;
    }
}

b, strong {
    font-weight: 600;
}
.uppercase {
    text-transform: uppercase;
}
p.intro {
    font-size: 22px;
}

/* navigation */
.breadcrumbs {
    position: relative;
    font-size: .9rem;
    font-weight: 200;
    text-decoration: underline;
    padding-left: 16px;
}
.breadcrumbs:before {
    content: "<";
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1.2;
}

/* font size */
.f-s-x-large {
   font-size: x-large; 
}

/* font weight */
.f-w-200 {
    font-weight: 200 !important;
}
.f-w-400 {
    font-weight: 400 !important;
}
.f-w-500 {
    font-weight: 500 !important;
}
.f-w-600 {
    font-weight: 600 !important;
}


/* text align */
.text-l,
.text-c,
.text-r {
    text-align: center;
}
@media(min-width: 768px) {
    .text-l {
        text-align: left;
    }
}
@media(min-width: 768px) {
    .text-r {
        text-align: right;
    }
}

/* text color */
.t-primary-color {
    color: var(--primary-color) !important;
}
.t-white {
    color: #fff !important;
}

/* link */
a {
    cursor: pointer;
}
a[href] {
	color: var(--body-color);
	text-decoration: underline;
}
a[href]:hover,
a[href]:focus {
	color: var(--primary-color);
}
a[href].link-color {
	color: var(--primary-color);
}
a[href].link-color:hover,
a[href].link-color:focus {
    color: #4f00be;
    filter: drop-shadow(2px 4px 6px rgb(102 0 246 / 20%));
}
a[data-toggle="tooltip"] {
    text-decoration: underline !important;
}
:focus {
    outline: none !important;
}

a[href="/user/login"] {
    font-size: 15px;
}

/* link breadcrumb */
.breadcrumb {
    padding: 0;
	background-color: transparent;
}
.breadcrumb a[href] {
	color: var(--primary-color);
}

/* link + arrow */
.link-arrow {
    position: relative;
    padding: 1rem 2rem 1rem 1rem;
    margin-left: -1rem;
}
.link-arrow:hover {
    color: var(--primary-color);
}
.link-arrow::after {
    content: " →";
    display: inline-block;
    position: absolute;
    font-size: larger;
    margin-left: 5px;
    transition: transform 300ms;
}
.link-arrow:hover:after {
    transform: translateX(5px);
}

/* link card */
.link-card {
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    border: 1px solid #e6e1fd;
    padding: 1rem;
}
.link-card img {
    justify-self: center;
    width: 60px;
    margin-bottom: 8px;
}
.link-card a:hover,
.link-card a:focus {
    color: var(--primary-color);
}