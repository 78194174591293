@charset "UTF-8";

.iframe-short-vid {
    position: relative;
    padding:100% 0 0 0;
    width: 100%;
}
.iframe-short-vid > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
}

/* 16 / 9 */
.iframe-vid-16_9 {
    padding:56.25% 0 0 0;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    overflow: hidden;
}