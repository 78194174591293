@charset "UTF-8";

/* dropzone */
.omnibook-dropzone {
    border: 0.125rem dashed var(--primary-color);
    background: #f5f3fd;
    ;
    padding: 3rem 2rem;
    text-align: center;
    border-radius: var(--border-radius);
}
/* dragover class added by js */
.omnibook-dropzone.dragover {
    background-color: #fff0ff;
}
.omnibook-dropzone .material-symbols-outlined {
    font-size: 40px;
    color: var(--primary-color);
}
/* dropzone dialog */
.dropzone-dialog > .modal-dialog > .modal-content > .modal-body {
    padding: 1rem;
}
.dropzone-dialog-logo {
    margin-bottom: 2rem;
}
.dropzone-dialog-title {
    margin-bottom: 3rem;
    text-align: center;
}
.dropzone-dialog-title {
    margin-bottom: 2rem;
}
.dropdown-dialog-progress {
    margin: 0 4rem 2rem;
}