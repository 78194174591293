@charset "UTF-8";

#floatingBtn,
#floatingBtnPrice {
    display: flex;
    position: fixed;
    left: 0;
    bottom: -1px;
    top: auto;
    right: auto;
    width: 100%;
    height: auto;
    z-index: 2;
    background-color: var(--secondary-color);
    box-shadow: 0 -16px 20px rgba(14,19,24,.04);
    transform: translateY(160px);
    transition: transform .6s ease-in-out;
}
#floatingBtn.show,
#floatingBtnPrice {
    transform: translateY(0);
}

.floating-button-inner {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 1rem;
    padding: 10px 16px;
}
@media(min-width:576px) {
    .floating-button-inner {
        height: 80px;
        padding: 0 16px;
    }
}

#floatingBtn.show.hidden,
#floatingBtnPrice.hidden {
    transform: translateY(160px) !important;
}

@media(max-width:575px) {
    .floating-button-inner > div:first-child {
        display: none;
    }
}

.floating-button-inner p {
    margin-bottom: 0;
}
.floating-cta {
    display: flex;gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.floating-cta .button {
    background-color: transparent;
    color: var(--body-color) !important;
    border-color: var(--body-color);
    border-radius: 2.5em;
}
@media(max-width:575px) {
    .floating-cta .button {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
    }
}

#hideBtn {
    font-size: 14px;
    text-decoration: underline;
}
#hideBtn:hover,
#hideBtn:focus {
    color: var(--primary-color);
}