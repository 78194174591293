@charset "UTF-8";

/* carousel home */
.carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: transparent;
}
.carousel-track-container {
    overflow: hidden;
}
.carousel-track {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 340px;
    gap: 2rem;
    transition: transform 0.5s ease-in-out;
    padding-left: 0;
}
.carousel-slide {
    flex: 0 0 200px; /* Chaque slide occupe 100% de la largeur */
    box-sizing: border-box;
    list-style: none;
    border-radius: 24px;
    background-color: #fbf9fe;
}
.carousel-slide-content {
    padding: 32px;
}
.carousel-slide:nth-child(6n + 1) {
    box-shadow: 4px 4px 0px 0px var(--primary-color);
}
.carousel-slide:nth-child(6n + 1):hover {
    box-shadow: 8px 8px 0px 0px var(--primary-color);
}
.carousel-slide:nth-child(6n + 2) {
    box-shadow: 4px 4px 0px 0px #e100f6;
}
.carousel-slide:nth-child(6n + 2):hover {
    box-shadow: 8px 8px 0px 0px #e100f6;
}
.carousel-slide:nth-child(6n + 2) .link-arrow:hover,
.carousel-slide:nth-child(6n + 2) .link-arrow:focus {
    color: #e100f6;
}
.carousel-slide:nth-child(6n + 3) {
    box-shadow: 4px 4px 0px 0px #f60090;
}
.carousel-slide:nth-child(6n + 3):hover {
    box-shadow: 8px 8px 0px 0px #f60090;
}
.carousel-slide:nth-child(6n + 3) .link-arrow:hover,
.carousel-slide:nth-child(6n + 3) .link-arrow:focus {
    color: #f60090;
}
.carousel-slide:nth-child(6n + 4) {
    box-shadow: 4px 4px 0px 0px #f60015;
}
.carousel-slide:nth-child(6n + 4):hover {
    box-shadow: 8px 8px 0px 0px #f60015;
}
.carousel-slide:nth-child(6n + 4) .link-arrow:hover,
.carousel-slide:nth-child(6n + 4) .link-arrow:focus {
    color: #f60015;
}
.carousel-slide:nth-child(6n+5) {
    box-shadow: 4px 4px 0px 0px #ff6600;
}
.carousel-slide:nth-child(6n+5):hover {
    box-shadow: 8px 8px 0px 0px #ff6600;
}
.carousel-slide:nth-child(6n+5) .link-arrow:hover,
.carousel-slide:nth-child(6n+5) .link-arrow:focus {
    color: #ff6600;
}
.carousel-slide:nth-child(6n) {
    box-shadow: 4px 4px 0px 0px #f6e100;
}
.carousel-slide:nth-child(6n):hover {
    box-shadow: 8px 8px 0px 0px #f6e100;
}
.carousel-slide:nth-child(6n) .link-arrow:hover,
.carousel-slide:nth-child(6n) .link-arrow:focus {
    color: #f6e100;
}
.carousel-slide-content>p:first-child {
    font-size: small;
}
.carousel-slide-content h4 {
    margin-bottom: 1rem;
}
.carousel-slide-content h4 + p {
    margin-bottom: 2rem;
}
.carousel-buttons {
    display: flex;
    position: relative;
    justify-content: flex-end;
}
.carousel-buttons>div {
    display: block;
    position: relative;
}
.carousel-button {
    position: relative;
    background: none;
    border: none;
    font-size: 2em;
    cursor: pointer;
}